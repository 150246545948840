<template>
  <v-container v-show="loadDone">
    <eagle-form :form-key="formKey">
      <template v-slot:footer>
        <div>
          <!-- <liff-member-block
            :members="members"
            :isMainManager="isMainManager"
            :control="isMainManager"
            :autoInvite="autoInvite"
            :shareConfig="shareConfig"
            :readonly="formStatus == 'create'"
            role="store"
            :storeId="storeId"
            @refresh="getMembers"
            class="mb-5"
          /> -->
          <v-btn
            v-if="isCancelStatus"
            @click="goProduct"
            width="100%"
            color="secondary"
            class="btn-min-width"
            >查看商品清單</v-btn
          >
        </div>
      </template>
    </eagle-form>

    <div v-if="false" class="mb-4">{{ shareConfig }}</div>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from "@/components/form/mixins/formMixin";
import formConfig from "./storeRegister";

export default {
  mixins: [formMixin],
  components: {
    // liffMemberBlock: () => import('@/modules/member/components/liffMemberBlock.vue')
  },
  data: () => ({
    formKey: "store-register-form",
    formStatus: "create", // 紀錄是否有申請的商家
    storeId: "",
    enableCreate: true,
    meta: {},
    inviteUrl: null,
    members: [],
    loadDone: false,
  }),
  mounted() {
    this.storeId = this.$store.getters[`member/storeId`];
    this.setInviteUrl();
    this.getMembers();
  },
  computed: {
    routeQuery() {
      return window.rootVm.$route.query;
    },
    // 不檢查是否被供應商拒絕合作
    forceUpdate() {
      return this.$route.query.forceUpdate;
    },
    forceCreate() {
      return this.$route.query.forceCreate;
    },
    cooperationStatus() {
      return this.$store.getters["store/cooperationStatus"];
    },
    // 有deleted_at代表是取消合作關係
    isCancelStatus() {
      if (!this.formData) return false;
      if (!this.formData.pivot) return false;
      return !!this.formData.pivot.deleted_at;
    },

    formData() {
      return this.$store.getters[`form/${this.formKey}/data`];
    },
    autoInvite() {
      return !!this.$route.query.autoInvite;
    },
    storeName() {
      return this.$store.getters[`member/storeName`];
    },
    providerId() {
      return this.$route.params.providerId;
    },
    memberId() {
      return this.$store.getters[`token/memberId`];
    },
    hasStore() {
      return this.$store.getters[`member/hasStore`];
    },
    store() {
      return this.$store.getters["store/store"];
    },
    // 是否為該store主要管理員
    isMainManager() {
      return this.$store.getters[`member/isStoreMainManager`];
    },
    disabledInvite() {
      if (
        this.cooperationStatus !=
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
      )
        return true;
      if (!this.isMainManager) return true;
      if (!this.hasStore) return true;
      return false;
    },
    liffDomain() {
      return this.$store.getters[`base/liffDomain`];
    },
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
    layoutProviderLineAtId() {
      return this.$store.getters[`base/layoutProviderLineAtId`];
    },
    layoutProviderPaymentTypes() {
      return this.$store.getters[`base/layoutProviderPaymentTypes`];
    },
    shareConfig() {
      if (!this.inviteUrl) return null;
      return [
        {
          type: "template",
          altText: "請點此加入我的商家",
          template: {
            type: "buttons",
            title: `
              我要邀請你加入${this.storeName}訂購${this.layoutProviderName}的行列
              請完成兩步驟, 成為共同管理員
              若你與${this.storeName}無關, 請忽略訊息
              `,
            text: "請依照以下按鈕操作",
            actions: [
              {
                type: "uri",
                label: `1. 加入${this.layoutProviderName}官方帳號`,
                uri: `https://line.me/R/ti/p/${this.layoutProviderLineAtId}`,
              },
              {
                type: "uri",
                label: "2. 成為共同管理員",
                uri: this.inviteUrl,
              },
            ],
          },
        },
      ];
    },
  },
  methods: {
    setInviteUrl() {
      this.inviteUrl = `${this.liffDomain}/provider-store/${this.providerId}/store/join/${this.storeId}`;
    },
    async getMembers() {
      this.$store.dispatch("loading/active");
      try {
        // 全抓
        let members = await this.$api.collection.storeMemberApi.managersIndex(
          this.storeId,
          { pager: 0 }
        );

        this.members = members.map((member) => {
          return {
            ...member,
            isMaster: member.IsStoreMaster,
          };
        });
      } catch (err) {
        // this.$snotify.error('讀取會員失敗')
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async copyInviteUrl() {
      try {
        await this.$copyText(this.inviteUrl);
      } catch (error) {
        console.warn(error);
      } finally {
        this.$snotify.success(this.inviteUrl, this.$t("copy.successfully"));
      }
    },
    async beforeFormInit() {
      await Promise.all([]);
    },
    validateBeforeSave() {
      // TODO
    },
    async afterSaveSuccessCallback(result) {},
    async readApi(target) {
      this.loadDone = false;
      try {
        console.log(this.forceCreate, this.formStatus, this.forceUpdate);
        if (this.forceCreate) throw "forceCreate";
        const res = await this.$api.collection.storeApi.readByProviderAndStore(
          this.storeId,
          this.providerId
        );
        console.log(res);
        this.formStatus = "update";
        if (this.forceUpdate) return res;

        if (!this.isCancelStatus) {
          if (
            res.pivot.status ==
            this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
          ) {
            this.$router.replace({
              name: "store-reject",
              params: { providerId: this.providerId },
            });
            return;
          }
          // 若狀態是申請中 => 去7.0.3
          if (
            res.pivot.status ==
            this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REQUEST
          ) {
            this.$router.replace({
              name: "store-request",
              params: { providerId: this.providerId },
            });
            return;
          }
          // 若已經是合作中 => 去商品頁
          if (
            res.pivot.status ==
            this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
          ) {
            this.$router.replace({
              name: "store-product-list",
              params: { providerId: this.providerId },
            });
            return;
          }
        }
        return res;
      } catch (err) {
        console.log("store_register_readApi:", err);
        this.formStatus = "create";
        this.$store.dispatch(`store/setDefault`);
        return this.store;
      } finally {
        this.loadDone = true;
      }
    },
    async updateApi(target, formData) {
      if (!this.enableCreate || this.formStatus == "update") {
        await this.apply(formData);
        return;
      }

      if (this.hasStore && !this.forceCreate) {
        this.$snotify.error("已申請過", "失敗");
        return;
      }
      this.$root.gaLogEvent("商店_點擊_註冊");
      const res = await this.$api.collection.storeApi.create(formData);
      res.pivot = formData.pivot;
      this.storeId = res.id;
      this.enableCreate = false;
      await this.apply(formData);
    },
    async apply(params) {
      const res = await this.$api.collection.storeApi.applyProvider(
        this.storeId,
        this.providerId,
        params
      );
      this.$store.dispatch(`store/setStore`, res);
      window.vueRouteChangePrevent.reset();
      await this.readMemberProfile();
      if (this.forceCreate) {
        this.$router.replace({
          name: "store-switch-identity",
          params: { providerId: this.providerId },
        });
      } else {
        this.$root.gaLogEvent("商店_點擊_申請合作");
        if (this.routeQuery && this.routeQuery.prevUrl) {
          this.$router.replace(this.routeQuery.prevUrl);
        } else {
          this.$router.replace({
            name: "store-product-list",
            params: { providerId: this.providerId },
          });
        }

        // this.$router.replace({
        //   name: "store-request",
        //   params: { providerId: this.providerId },
        // });
      }
    },
    async readMemberProfile() {
      try {
        const data = await this.$api.collection.memberApi.profile();
        this.$store.dispatch(`member/set`, data);
      } catch (error) {
        console.warn(error);
      }
    },
    goProduct() {
      this.$router.push({
        name: "store-product-list",
        params: { providerId: this.providerId },
      });
    },
    getFormConfig() {
      return formConfig;
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
